<template>
  <i-modal
    :title="setModalTitle"
    :value="value"
    :load="load"
    @submit="submit"
    @input="$emit('input', $event)"
  >
    <v-row class="my-0 align-items-center">
      <!-- Autocomplete mode special -->
      <v-col v-if="mode === 'special'" class="pt-0" cols="12">
        <ValidationProvider
          vid="products"
          :name="$tc('product', 1)"
          rules="required"
          v-slot="{ errors }"
        >
          <v-autocomplete
            autocomplete="off"
            outlined
            v-model="productprice.product"
            :items="products"
            item-text="name"
            item-value="pk"
            :error-messages="errors[0]"
            :label="$tc('product', 1)"
            class="secondary--text"
            clearable
            @change="setCost"
          />
        </ValidationProvider>
      </v-col>

      <!-- Autocomplete mode customer -->
      <v-col v-if="mode === 'customer_special'" class="pt-0" cols="12">
        <ValidationProvider
          vid="customer"
          :name="$tc('customer', 1)"
          rules="required"
          v-slot="{ errors }"
        >
          <v-autocomplete
            autocomplete="off"
            outlined
            v-model="productprice.customer"
            :items="customers"
            :item-text="(item) => `${item.first_name} ${item.last_name}`"
            item-value="pk"
            :error-messages="errors[0]"
            :label="$tc('customer', 1)"
            class="secondary--text"
            clearable
            @change="setSpecialCost"
          />
        </ValidationProvider>
      </v-col>

      <!-- Por porcentaje -->
      <v-col cols="12" sm="4" class="pt-0" v-if="haveCost">
        <v-skeleton-loader v-if="load" type="button" tile></v-skeleton-loader>
        <v-switch
          v-show="prodCost > 0 && !load"
          class="v-switch"
          color="secondary"
          name="autoprice"
          :label="$t('bypercent')"
          v-model="productprice.autoprice"
        />
      </v-col>

      <!-- Costos -->
      <v-col cols="6" sm="4" class="pt-0" v-if="haveCost">
        <i-money
          class="font-weight-black grey--text"
          mode="text"
          :class="{ title: !$vuetify.breakpoint.xsOnly }"
          :label="$tc('cost', 2)"
          :value="prodCost"
        />
      </v-col>

      <!-- Costos de producción -->
      <v-col v-if="haveCost" class="pt-0" cols="6" sm="4">
        <i-money
          outlined
          :class="profitClass"
          :value="profit"
          mode="text"
          :label="`${$tc('profit', 2)}:`"
        />
      </v-col>

      <!-- Porcentaje -->
      <v-col sm="6" cols="12" v-if="haveCost">
        <v-skeleton-loader v-if="load" type="text" tile></v-skeleton-loader>
        <ValidationProvider
          vid="percent"
          :name="$tc('percent', 1)"
          rules="required|double:2"
          v-slot="{ errors }"
        >
          <v-text-field
            outlined
            v-show="!load"
            v-model="productprice.percent"
            :error-messages="errors[0]"
            :label="$t('percent')"
            :disabled="!productprice.autoprice"
          />
        </ValidationProvider>
      </v-col>

      <!-- Precio del producto -->
      <v-col sm="6" cols="12" v-if="haveCost">
        <v-skeleton-loader v-if="load" type="text" tile></v-skeleton-loader>
        <ValidationProvider
          vid="price"
          :name="$tc('price', 0)"
          :rules="{
            required: true,
            min_value: prodCost
          }"
          v-slot="{ errors }"
        >
          <i-money
            outlined
            v-show="!load"
            v-model="productprice.price"
            :error-messages="errors[0]"
            :label="$tc('price', 0)"
            :disabled="productprice.autoprice"
          />
        </ValidationProvider>
      </v-col>

      <!-- Alerta costo para costos menores o iguales a cero -->
      <v-col cols="12">
        <v-alert type="warning" outlined v-show="prodCost <= 0">
          <span class="font-weight-black title">{{
            `${$tc('costalert')}`
          }}</span>
        </v-alert>
      </v-col>
    </v-row>
  </i-modal>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    toEdit: {
      type: Object,
      default: () => null
    },
    cost: {
      type: [Number, String],
      default: 0
    },
    product: {
      type: Number,
      default: null
    },
    companyId: {
      type: Number,
      default: null
    },
    customer: {
      type: Number,
      default: null
    },
    mode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      productprice: {
        name: '',
        price: 0,
        autoprice: false,
        percent: 0,
        product: null,
        customer: this.customer
      },
      load: false,
      products: [],
      customers: [],
      prodCost: this.cost
    }
  },
  watch: {
    'productprice.product': {
      handler(val) {
        const selectedProduct = this.products.find((m) => val === m.pk)
        if (selectedProduct) {
          this.selectProduct = selectedProduct
          this.setCost(this.selectProduct)
        }
      }
    },
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.edit = this.toEdit !== null
          this.productprice =
            this.toEdit !== null
              ? { ...this.toEdit }
              : {
                  name: '',
                  price: 0,
                  autoprice: false,
                  percent: 0,
                  product: null,
                  customer: this.customer
                }
        }
      }
    },
    'productprice.price': {
      handler(val) {
        if (val < 0) {
          this.productprice.price = Math.abs(val)
        }
        if (this.prodCost > 0 && !this.productprice.autoprice) {
          this.productprice.percent =
            Math.floor((this.productprice.price * 100) / this.prodCost) - 100
        }
      }
    },
    'productprice.percent': {
      handler(val) {
        if (this.productprice.autoprice) {
          let estimated = parseFloat(this.prodCost * val) / 100
          this.productprice.price = (
            parseFloat(this.prodCost) + parseFloat(estimated)
          ).toFixed(2)
        }
      }
    }
  },
  methods: {
    /**
     * submit
     * Método que maneja la creación o edición de un precio de un producto.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async submit() {
      if (!this.load) {
        this.load = true
        try {
          if (this.selectProduct && this.level !== undefined) {
            this.productprice.product = this.selectProduct.pk
            this.productprice.level = this.level
          }
          const apiMethod =
            this.product !== null || this.customer !== null
              ? this.edit
                ? this.$api.product.prices.edit
                : this.$api.product.prices.create
              : this.edit
              ? this.$api.product.processprices.edit
              : this.$api.product.processprices.create

          const price = await apiMethod({
            pk: this.productprice.pk,
            form: this.productprice
          })
          this.$emit('done', price.data)
          this.$toast.success(
            `${this.$tc('price', 1)} ${this.$tc('edited', 1)}`
          )
          if (this.mode === 'customer_special') {
            const current_customer = this.customers.find(
              (customer) => customer.pk === this.productprice.customer
            )
            await this.$api.customer.edit({
              pk: this.productprice.customer,
              form: {
                ...current_customer,
                company: current_customer.id,
                hasUser: current_customer.user !== null,
                origin: current_customer.origin.id,
                special: true
              }
            })
          }
        } catch (error) {
          console.error(error)
        } finally {
          this.load = false
          this.$emit('input', false)
        }
      }
    },
    /**
     * getProducts
     * Método que obtiene la lista de productos activos.
     * La lista se almacena en la propiedad 'products'.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getProducts() {
      const { data } = await this.$api.product.list({
        opt: {
          params: {
            dropdown_list: true,
            active: true,
            company_id: this.companyId
          }
        }
      })
      this.products = data
    },
    /**
     * getLevel
     * Método que obtiene los tipos de cliente.
     * El tipo de cliente se almacena en la propiedad 'level'.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getLevel() {
      const { data } = await this.$api.customer.show({
        pk: this.customer || this.productprice.customer
      })
      this.level = data.level.id
    },
    /**
     * getCustomers
     * Método que obtiene la lista de clientes de la empresa.
     * La lista se almacena en la propiedad 'customers'.
     *
     * Rodrigo Boet <rudmanmrrod at gmail.com>
     */
    async getCustomers() {
      const { data } = await this.$api.customer.list({
        opt: {
          params: {
            active: true,
            company_id: this.companyId
          }
        }
      })
      this.customers = data.results
    },
    /**
     * setCost
     * Método que establece el costo del producto seleccionado. Si hay un
     * cliente, también se llama a getLevel para actualizar el tipo de cliente.
     *
     * @param {Object} selectProduct - El producto seleccionado.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    setCost(selectProduct) {
      this.prodCost = selectProduct?.cost || this.cost
      if (this.customer) this.getLevel()
    },
    /**
     * setSpecialCost
     * Método que establece el costo del producto y el producto
     * para el caso especial de cliente
     *
     * Rodrigo Boet <rudmanmrrod at gmail.com>
     */
    setSpecialCost() {
      this.productprice.product = this.product
      this.prodCost = this.cost
      this.getLevel()
    }
  },
  computed: {
    /**
     * setModalTitle
     * Método para asignarle el valor al título de la modal
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    setModalTitle() {
      if (this.mode === 'product' && this.productprice.name) {
        return this.productprice.name
      }
      if (this.mode === 'customer' && this.productprice.customer) {
        return this.productprice.customerName
      }
      if (this.mode === 'customer' && this.productprice.customer) {
        return this.productprice.customer
      }
      if (this.mode === 'special' && this.productprice.productName) {
        return this.productprice.productName
      }
      if (this.productprice.name) {
        return `
          ${this.$t('add', { value: this.$tc('price', 0) })}:
          ${this.productprice.name}
        `
      }
      return this.$t('add', { value: this.$tc('new') })
    },
    /**
     * haveCost
     * Verifica si existe un producto y si su costo es mayor que cero.
     *
     * @returns {boolean} - Retorna true si el costo del producto es mayor que
     * cero y el producto es válido; de lo contrario, retorna false.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    haveCost() {
      return this.prodCost > 0 && this.productprice.product !== null
    },
    /**
     * profit
     * Computa la ganancia calculando la diferencia entre el precio del producto
     * y su costo.
     *
     * @returns {number} - La ganancia.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    profit() {
      return this.productprice.price - this.prodCost
    },
    /**
     * profitClass
     * Computa las clases CSS a aplicar según el estado de la ganancia.
     *
     * @returns {Object} - Un objeto con las clases CSS.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    profitClass() {
      return {
        'font-weight-black': true,
        'error--text': this.profit < 0,
        'success--text': this.profit >= 0
      }
    }
  },
  mounted() {
    this.getProducts()
    this.getCustomers()
  }
}
</script>
