<template>
  <v-row class="my-0">
    <dialprice
      v-model="dialogPrice"
      :to-edit="toEditPrice"
      :cost="cost"
      :customer="customer"
      :mode="mode"
      :product="product"
      :companyId="company.pk"
      @done="reload = true"
    />
    <diallevel :to-edit="toEditLevel" @done="getLevels" v-model="dialogLevel" />
    <v-col v-if="mode" cols="12" class="pt-0 pb-3">
      <i-card-list
        dontSearch
        class="mt-2 px-2"
        :app="
          mode === 'process' ? 'product.processprices' : 'product.productprices'
        "
        :api="mode === 'process' ? 'product.processprices' : 'product.prices'"
        :dontCreate="otherMode"
        :headers="headers"
        :title="setTitle(mode)"
        :opt="{
          mode: mode,
          customer: customer,
          product: product,
          template: template,
          detail: detail,
          subdetail: subdetail != '' ? subdetail : null
        }"
        :reload.sync="reload"
        @click:edit="open($event)"
        @click:create="openLevel()"
      >
        <template v-if="mode === 'product'" v-slot:noData="{ value }">
          <span v-if="value" class="headline font-weight-black error--text">
            ({{ $t('levelRequired') }})
          </span>
        </template>
        <template
          v-if="mode === 'product' || mode === 'process'"
          v-slot:[`item.pk`]="{ value }"
        >
          <span class="title font-weight-black primary--text">
            {{ value }}
          </span>
        </template>
        <template
          v-if="mode === 'product' || mode === 'process'"
          v-slot:[`item.detail`]="{ value }"
        >
          <span class="title font-weight-black primary--text">
            {{ value }}
          </span>
        </template>
        <template
          v-if="mode === 'product' || mode === 'process'"
          v-slot:[`item.subdetail`]="{ value }"
        >
          <span class="title font-weight-black primary--text">
            {{ value }}
          </span>
        </template>
        <template
          v-if="mode === 'product' || mode === 'process'"
          v-slot:[`item.name`]="{ value }"
        >
          <p class="title font-weight-black primary--text mb-2">
            {{ value }}
          </p>
        </template>
        <template
          v-if="mode === 'customer'"
          v-slot:[`item.customerName`]="{ value }"
        >
          <span class="title font-weight-black primary--text">
            {{ value }}
          </span>
        </template>
        <template
          v-if="mode === 'special'"
          v-slot:[`item.productName`]="{ value }"
        >
          <span class="title font-weight-black primary--text">
            {{ value }}
          </span>
        </template>
        <template
          v-if="mode === 'customer_special'"
          v-slot:[`item.productName`]="{ item }"
        >
          <span class="title font-weight-black primary--text">
            {{ item.customerRealName }}
          </span>
        </template>
        <template v-slot:[`item.price`]="{ value }">
          <i-money :value="value" class="primary--text" mode="text" />
          <p class="mb-0">
            <span
              :class="
                value - cost < 0
                  ? 'error--text font-weight-black'
                  : 'primary--text font-weight-black'
              "
            >
              {{ $t('profit') }}:
            </span>
            <i-money class="primary--text" mode="text" :value="value - cost" />
          </p>
        </template>
        <template v-slot:[`item.percent`]="{ value }">
          <span class="primary--text">
            {{ `${value}%` }}
          </span>
        </template>
        <template v-if="mode === 'product'" v-slot:alert="{ value }">
          <v-alert v-if="value" outlined type="info" dismissible>
            <ul>
              <li class="title-responsive">
                {{ $t('priceZero') }}
              </li>
              <li class="title-responsive">
                {{ $t('with_autoprice') }}
              </li>
            </ul>
          </v-alert>
        </template>
      </i-card-list>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import dialprice from './dialPrice.vue'
import diallevel from '../../components/level/modal.vue'
export default {
  components: {
    diallevel,
    dialprice
  },
  props: {
    product: {
      type: [Number, String],
      default: null
    },
    customer: {
      type: [Number, String],
      default: null
    },
    mode: {
      type: String,
      required: true
    },
    template: {
      type: [Number, String]
    },
    detail: {
      type: [Number, String]
    },
    subdetail: {
      type: [Number, String],
      default: null
    },
    cost: {
      type: [Number, String],
      default: 0
    }
  },
  mounted() {
    this.getLevels()
  },
  data() {
    return {
      loading: false,
      prices: [],
      reload: false,
      dialogPrice: false,
      toEditPrice: null,
      dialogLevel: false,
      toEditLevel: null,
      api: '',
      app: '',
      opt: null,
      levels: 0,
      otherMode: true
    }
  },
  computed: {
    headers() {
      return [
        {
          value:
            this.mode === 'product' ||
            this.mode === 'detail' ||
            this.mode === 'process'
              ? 'name'
              : this.mode === 'customer'
              ? 'customerName'
              : 'productName'
        },
        {
          text: this.$tc('price', 1),
          value: 'price'
        },
        {
          text: this.$tc('percent', 2),
          value: 'percent'
        }
      ]
    },
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      company: 'company/getCompanyData',
      isAdmin: 'session/isAdmin'
    })
  },
  methods: {
    /**
     * setTitle
     * Función para determinar el nombre de la tabla dependiendo del modo
     */
    setTitle(mode) {
      if (mode === 'product' || mode === 'process' || mode === 'detail') {
        return this.$tc('level', 1)
      }
      if (mode === 'customer' || mode === 'customer_special') {
        return this.$tc('special_customer', 1)
      }
      return this.$tc('pricespecial', 1)
    },
    open(item) {
      // Paul quitó las condiciones
      //colocar condiciones para inhabilitar en la orden de venta
      this.toEditPrice = item === undefined ? null : item
      this.dialogPrice = true
    },
    openLevel(item) {
      if (this.mode == 'special' || this.mode == 'customer_special') {
        this.toEditPrice = item === undefined ? null : item
        this.dialogPrice = true
      } else {
        this.getLevels()
        this.toEditLevel = item === undefined ? null : item
        this.dialogLevel = true
      }
    },
    async getLevels() {
      const response = await this.$api.customer.level.list({
        opt: {
          params: {
            company_id: this.company.pk
          }
        }
      })
      this.levels = response.data.results.length
      this.reload = true
      this.createPrices()
      this.changeOtherMode()
    },
    changeOtherMode() {
      if (
        ((this.mode == 'product' && this.levels < 4) ||
          this.mode == 'special' ||
          this.mode == 'customer_special') &&
        (this.getPermissions([`base_config.add_level`]) || this.isAdmin)
      ) {
        this.otherMode = false
      } else {
        this.otherMode = true
      }
    },
    async createPrices() {
      await this.$api.product.pricescreate({
        opt: {
          params: {
            product: this.product,
            mode: 'product',
            company_id: this.company.pk
          }
        }
      })
    }
  }
}
</script>
